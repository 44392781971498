import { Controller } from "@hotwired/stimulus";
import mapbox from "mapbox-gl";
import * as turf from "@turf/turf";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import * as MapboxDrawWaypoint from 'mapbox-gl-draw-waypoint';
import { getAreaOfPolygon } from "geolib"

export default class extends Controller {
    static targets = ["editor", "table", "plotInfo", "controls", "toggleButtonText"];

    static values = {
        centerIcon: String,
        plots: Array,
        token: String,
        companyId: Number,
        translation: Object,
        mapVisible: Boolean
    }

    updatedPlots = {}
    connect() {
        console.log('map visible', this.mapVisibleValue)
        if (this.mapVisibleValue) {
            this.showMap()
        }
    }

    toggle() {
        if (this.mapVisibleValue && Object.keys(this.updatedPlots).length > 0) {
            const confirmation = confirm(this.translationValue.exit_without_saving);

            if (confirmation) {
                this.saveCoordinates().then(() => {
                    this.mapVisibleValue = false;
                    this.showTable();
                }).catch(error => {
                    console.error(error)
                })
                return;
            } else {
                this.cancelOperation()
            }
        }

        this.mapVisibleValue = !this.mapVisibleValue;
        const url = new URL(window.location)
        const input = document.getElementById('show_map')
        if (this.mapVisibleValue) {
            input.value = true
            url.searchParams.set('q[show_map]', 'true')
            this.showMap()
        } else {
            input.value = false
            url.searchParams.set('q[show_map]', 'false')
            this.showTable();
        }

        history.pushState({}, '', url)
    }

    showMap() {
        this.toggleButtonTextTarget.innerText = this.translationValue.toggle_table_view
        this.editorTarget.style.display = "block";
        this.tableTarget.style.display = "none";
        this.plotInfoTarget.style.display = "block";
        this.controlsTarget.classList.remove("hidden-map-controls");
        if (!this.map) {
            this.initMap()
        }
    }

    showTable() {
        this.toggleButtonTextTarget.innerText = this.translationValue.toggle_map_view
        this.editorTarget.style.display = "none";
        this.tableTarget.style.display = "block";
        this.plotInfoTarget.style.display = "none";
        this.controlsTarget.classList.add("hidden-map-controls");
    }

    initMap() {
        const mapCenter = this.plotsValue.find(plot => {
            return plot.entrance_point
        })

        mapbox.accessToken = this.tokenValue;
        this.map = new mapbox.Map({
            container: this.editorTarget,
            style: 'mapbox://styles/mapbox/satellite-streets-v12',
            center: [mapCenter?.entrance_point.longitude || 0, mapCenter?.entrance_point.latitude || 0] ,
            zoom: 9
        });

        let modes = MapboxDraw.modes;
        modes = MapboxDrawWaypoint.enable(modes);

        this.draw = new MapboxDraw({ modes, controls: {}, displayControlsDefault: false});
        this.map.addControl(this.draw);

        this.map.loadImage(this.centerIconValue, (error, image) => {
            if (error) {
                console.error(`Error loading marker image: ${error}`);
                return;
            }
            this.map.addImage('center_icon', image);
        });

        this.map.on("load", () => {
            // console.log(this.map.getStyle().layers);
            this.drawPlots();
        })

        this.map.on('draw.update', this.updateArea.bind(this));

        // this.map.on('draw.delete', this.updateArea.bind(this));
        // No he encontrado la forma de que funcione el botón de basura para menos de 4 puntos, desaparece la parcela, desactivo el botón
        // this.map.off('draw.delete', this.updateArea.bind(this));
        // this.map.on('draw.delete', (e) => {
        //     this.handleDelete(e);
        // })
        // this.map.on('draw.delete', (e) => {
        //     let shouldReverseDeletion = false;
        //
        //     e.features.forEach(feature => {
        //         const coordinates = feature.geometry.coordinates[0];
        //         if (coordinates.length <= 4) {
        //             alert(this.translationValue.not_allow_to_delete_point);
        //             shouldReverseDeletion = true;
        //         }
        //     })
        //
        //     if (shouldReverseDeletion) {
        //         e.features.forEach((feature) => this.draw.add(feature));
        //         return;
        //     }
        //     this.updateArea(e)
        // })
        this.map.on('draw.selectionchange', this.onSelectionChange.bind(this))
        this.map.on('contextmenu', this.deletePoint.bind(this))
    }

    handleDelete(e) {
        console.log(e)
        let shouldReverseDeletion = false;

        e.features.forEach(feature => {
            const coordinates = feature.geometry.coordinates[0];
            console.log(coordinates)
            if (coordinates.length <= 4) {
                alert(this.translationValue.not_allow_to_delete_point);
                shouldReverseDeletion = true;
            }
        })

        if (shouldReverseDeletion) {
            e.features.forEach((feature) => this.draw.add(feature));
            return;
        }
        this.updateArea(e)
    }

    onSelectionChange(event) {
        console.log('cambias evento')
        const currentPlot = this.plotsValue.find( p => p.id === event.features[0]?.properties?.productive_space_id);
        this.changePlotInfo(currentPlot)
    }

    changePlotInfo(plot) {
        const currentUrl = new URL(window.location.href);
        const locale = currentUrl.pathname.split('/')[1];
        const entity_name = plot?.entity ? ` <a target="_blank" href=/${locale}/entities/${plot?.entity?.id}/edit> ${plot.entity?.name} ${plot.entity?.surname}</a>` : ""

        if (plot) {
            this.plotInfoTarget.innerHTML = `
          <div>
            <p><strong>${this.translationValue.entity_code}:</strong> ${plot.entity?.code}</p>
            <p><strong>${this.translationValue.entity}:</strong>${entity_name}</p>
            <p><strong>${this.translationValue.plot_code}</strong> ${plot.code}</p>
            <p><strong>${this.translationValue.plot_name}</strong> ${plot.name}</p>
            <p><strong>${this.translationValue.header_area}</strong> ${parseFloat(this.updatedPlots[plot.id]?.area ?? plot.area).toFixed(2) }</p>
          </div>
          `
        } else {
            this.plotInfoTarget.innerHTML = `<p>${this.translationValue.info}</p>`
        }
    }

    deletePoint(event) {
        const features = this.draw.getAll().features;
        const selected = this.draw.getSelectedPoints()?.features?.at(0);

        if (!selected) return

        for (const f of features) {
            const coordinates = f.geometry.coordinates[0]
            const index = coordinates.findIndex( coord => {
                const lng = parseFloat(selected.geometry.coordinates[0])
                const lat = parseFloat(selected.geometry.coordinates[1])
                return lng === parseFloat(coord[0]) && lat === parseFloat(coord[1])
            })

            if (index >= 0) {
                if (coordinates.length <= 4) {
                    alert(this.translationValue.not_allow_to_delete_point);
                    return;
                }

                if (coordinates.length > 4 ) coordinates.splice(index, 1); // Esto tampoco deberia ser necesario

                const areaCalculated = this.calculateArea((f.geometry.coordinates))
                this.updatedPlots[f.properties.productive_space_id] = { area: areaCalculated, bounds: coordinates}
                const currentPlot = this.plotsValue.find( p => p.id === f.properties?.productive_space_id);
                this.changePlotInfo(currentPlot);
              if (coordinates.length === 1) {
                    // Este caso ya nunca se deberia pero ahi queda por si se cambia la logica
                    // console.log(f.id)
                    this.draw.delete(f.id)
                } else {
                    this.draw.add({
                        ...f,
                        geometry: {
                            ...f.geometry,
                            coordinates: [coordinates]
                        }
                    })
                }

                break;
            }
        }
    }

    calculateArea(coordinates= []) {
        if (!Array.isArray(coordinates) || coordinates.length === 0 || !Array.isArray(coordinates[0]) || coordinates[0].length === 0) {
            console.error('Invalid coordinates', coordinates)
            return null;
        }
        return getAreaOfPolygon(coordinates?.[0]) / 10000;
    }

    updateArea(e) {
        if (e) {
            const coordinates = e.features[0]?.geometry?.coordinates;

            if (!coordinates || !Array.isArray(coordinates[0]) || coordinates[0].length < 3) {
                console.error("Cannot update aread due to insufficient points.")
                return;
            }

            const areaCalculated = this.calculateArea(e.features[0].geometry.coordinates);

            if (areaCalculated === null) {
                console.error('System not able to updateArea');
                return;
            }

            this.updatedPlots[e.features[0].properties.productive_space_id] = {
                area: areaCalculated,
                bounds: e.features[0].geometry.coordinates[0]
            }
            const currentPlot = this.plotsValue.find( p => p.id === e.features[0].properties.productive_space_id);
            this.changePlotInfo(currentPlot);
        }
    }
    getBounds() {
        let bounds = []

        this.plotsValue.forEach((plot) => {
            if (plot.bounds && plot.bounds.length) {
                plot.bounds.forEach((bound) => {
                    bounds.push([bound.longitude, bound.latitude])
                })
            }
        })

        return bounds
    }
    drawPlots() {
        const plotsWithoutBounds = []
        if (this.plotsValue) {
            this.plotsValue.forEach((plot, index) => {
                if (plot.bounds.length) {
                    let initPoint, endPoint
                    const coordinates = plot.bounds.map((bound) => [bound.longitude, bound.latitude])

                    initPoint = [plot.bounds[0].longitude, plot.bounds[0].latitude]
                    coordinates.push(initPoint)

                    const feature = {
                        type: "Feature",
                        properties: {
                            productive_space_id: plot.id
                        },
                        geometry: {
                            type: "Polygon",
                            coordinates: [coordinates]
                        }
                    }
                    this.draw.add(feature);
                } else if (plot.entrance_point) {
                    plotsWithoutBounds.push(plot)
                }
            })
            this.createEntrancePoints(plotsWithoutBounds);
        }
    }

    createEntrancePoints(plots) {
        const features = plots.map(plot => {
            if (plot.entrance_point.longitude >= -180 && plot.entrance_point.longitude <= 180 && plot.entrance_point.latitude >= -90 && plot.entrance_point.latitude <= 90) {
                const point = {
                    type: "Feature",
                    geometry: {
                        type: "Point",
                        coordinates: [plot.entrance_point.longitude, plot.entrance_point.latitude]
                    },
                    properties: {
                        productive_space_id: plot.id,
                        markerColor: 'red'
                    }
                }
                return point
            } else {
                console.error(`Invalidd coordinates for plot ID:${plot.id}, CODE: ${plot.code} - longitude: ${plot.entrance_point.longitude}, latitude: ${plot.entrance_point.latitude}`)
            }
        }).filter(noEntrancePoint => noEntrancePoint)

        const sourceName = `centers`;
        const layerName = `markers`;

        if (this.map.getLayer(layerName)) {
            this.map.removeLayer(layerName);
        }
        if (this.map.getSource(sourceName)) {
            this.map.removeSource(sourceName);
        }


        this.map.addSource(sourceName, {
            'type': 'geojson',
            'data': {
                'type': 'FeatureCollection',
                'features': features
            }
        });

        const drawLayers = this.map.getStyle().layers.filter(layer => layer.id.startsWith('gl-draw'));
        const lastDrawLayerId = drawLayers.length > 0 ? drawLayers[drawLayers.length - 1].id : null;

        this.map.addLayer({
            'id': layerName,
            'type': 'symbol',
            'source': sourceName,
            'layout': {
                'icon-image': 'center_icon',
                'icon-size': 1
            }
        }, lastDrawLayerId);

        // this.map.moveLayer(layerName);

        this.map.on('click', layerName, (e) => {
            const clickedFeatures = this.map.queryRenderedFeatures(e.point, { layers: [layerName]});

            if (clickedFeatures.length) {
                const clickedFeature = clickedFeatures[0];
                const plotId = clickedFeature.properties.productive_space_id;

                const plot = plots.find(p => p.id === plotId);
                if (plot) this.changePlotInfo(plot)
            }

        })
    }

    async saveCoordinates() {
        const invalidAmountOfPoints = Object.keys(this.updatedPlots).filter(plotId => {
            const bounds = this.updatedPlots[plotId].bounds;
            return bounds.length < 4;
        });

        if (invalidAmountOfPoints.length > 0 ) {
            alert(this.translationValue.less_than_four_points_alert);
            return;
        }
        try {
            const response = await fetch(`/companies/${this.companyIdValue}/productive_spaces/update_coordinates_only.json`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                },
                body: JSON.stringify(({ plots: this.updatedPlots }))
            });
            if (!response.ok) throw new Error('ERROR')


            // const updatedData = await response.json();
            // this.plotsValue = updatedData.plots;

            this.draw.changeMode("simple_select")

        } catch (error) {
            console.error('Error', error);
        }
    }

    cancelOperation() {
        this.updatedPlots = {};
        this.initMap()
        this.showTable();
    }

    clearMap() {
        this.plotsValue.forEach((plot) => {
            if (this.map.getSource(`plot_${plot.id}`)) {
                this.map.removeLayer(`plot_layer${plot.id}`);
                this.map.removeLayer(`plot_layer${plot.id}_limits`);
                this.map.removeSource(`plot_${plot.id}`)
            }
        })
    }

}